<template>
  <v-container fill-height>
    <v-row justify="center" align="center" no-gutters>
      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters>
          <v-alert color="red lighten-1" type="error" v-if="alerts" dense>
            {{ alerts }}
          </v-alert>
          <v-alert color="green lighten-1" type="success" v-if="messages" dense>
            {{ messages }}
          </v-alert>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="userApplicationInfo">
      <v-col cols="12">
        <v-alert dark color="primary">
          Application for {{ userApplicationInfo.application_type }}. Status:
          {{ applicationStatus }}
        </v-alert>
      </v-col>

      <v-dialog v-model="editReviewDialog" persistent max-width="500px">
        <v-card>
          <v-card-title>Edit Review</v-card-title>
          <v-card-subtitle>Round {{ editReviewFields.round }}</v-card-subtitle>
          <v-card-text>
            <v-radio-group v-model="editReviewFields.score">
              <v-radio
                v-for="s in scores"
                :key="s"
                :label="reviewScoreDescription(s)"
                :color="reviewScoreColor(s)"
                :value="s"
              >
                <template v-slot:label>
                  <v-chip label outlined :color="reviewScoreColor(s)">
                    {{ reviewScoreDescription(s) }}
                  </v-chip>
                </template>
              </v-radio>
            </v-radio-group>
            <v-text-field
              v-model="editReviewFields.comment"
              label="Comment"
            ></v-text-field>
            <v-card-actions class="justify-center">
              <v-btn type="submit" color="primary" @click="handleEditReview()">
                Confirm
              </v-btn>
              <v-btn type="submit" color="grey" @click="closeEditReview()">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-col>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Basic Application Info</v-toolbar-title>
          </v-toolbar>
          <v-list two-line>
            <v-list-item-group>
              <template v-for="(value, field, index) in editFields">
                <v-list-item :key="field">
                  <template v-if="fieldsEditing[field]">
                    <v-list-item-content>
                      <v-text-field
                        v-model="editFields[field]"
                        :label="infoFields[field]"
                      ></v-text-field>
                    </v-list-item-content>
                    <v-list-item-action v-if="isAdmin">
                      <v-btn
                        icon
                        color="success"
                        @click="handleConfirmEditField(field)"
                      >
                        <v-icon>mdi-check-circle</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="error"
                        @click="handleCancelEditField(field)"
                      >
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ infoFields[field] }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ userApplicationInfo[field] }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action v-if="field == 'ads_link'">
                      <v-btn
                        icon
                        color="grey"
                        target="_blank"
                        :href="userApplicationInfo[field]"
                      >
                        <v-icon>mdi-link</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-action v-if="isAdmin">
                      <v-btn icon color="grey" @click="handleEditField(field)">
                        <v-icon>mdi-pencil-circle</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider
                  v-if="index < editFields.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="3" align="center">
        <v-card v-if="userApplicationInfo.photo_thumbnail">
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Photo</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-avatar width="160" height="180" tile>
              <v-img :src="userApplicationInfo.photo_thumbnail"></v-img>
            </v-avatar>
          </v-card-text>
        </v-card>

        <br />

        <v-dialog persistent v-model="deleteMaterialDialog" max-width="500px">
          <v-card>
            <v-card-title class="headline">Remove</v-card-title>
            <v-card-subtitle>
              you are about to delete uploaded material
            </v-card-subtitle>
            <v-card-actions class="justify-center">
              <v-btn color="grey" @click="deleteMaterialClose">Cancel</v-btn>
              <v-btn color="primary" @click="deleteMaterialConfirm">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog persistent v-model="uploadMaterialDialog" max-width="500px">
          <v-card>
            <v-card-title class="headline">Upload Material</v-card-title>

            <v-card-text>
              <v-file-input
                ref="materialUpload"
                @change="selectFile"
                chips
                show-size
                counter
                :accept="fileTypes.doc"
              ></v-file-input>
            </v-card-text>
            <v-card-actions v-if="waiting" class="justify-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-card-actions>
            <v-card-actions v-else class="justify-center">
              <v-btn color="grey" @click="uploadMaterialClose">Cancel</v-btn>
              <v-btn
                color="primary"
                :disabled="!uploadFile"
                @click="uploadMaterialSubmit"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Material</v-toolbar-title>
          </v-toolbar>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              icon
              large
              @click="getMaterial(false)"
              v-if="userApplicationInfo.material"
            >
              <v-icon>mdi-eye-circle</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              icon
              large
              @click="getMaterial(true)"
              v-if="userApplicationInfo.material"
            >
              <v-icon>mdi-download-circle</v-icon>
            </v-btn>
            <template v-if="isAdmin">
              <v-btn
                color="error"
                icon
                large
                @click="handleDeleteMaterial"
                v-if="userApplicationInfo.material"
              >
                <v-icon>mdi-delete-circle</v-icon>
              </v-btn>
              <v-btn
                color="grey"
                icon
                large
                @click="handleUploadMaterial"
                v-else
              >
                <v-icon>mdi-cloud-upload</v-icon>
              </v-btn>
            </template>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>

        <br />
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Print</v-toolbar-title>
          </v-toolbar>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-progress-circular
              v-if="waiting"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <v-btn color="success" icon large @click="handlePrint()" v-else>
              <v-icon>mdi-printer</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>

        <br />
        <v-card v-if="isAdmin && applicationStatus == 'submitted'">
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Actions</v-toolbar-title>
          </v-toolbar>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-progress-circular
              v-if="waiting"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <v-btn color="warning" @click="handleReturn()" v-else>
              Return Application
            </v-btn>
            <v-dialog persistent v-model="returnDialog" max-width="640px">
              <v-card>
                <v-card-title>
                  确认退回申请/Confirm Send Back Application
                </v-card-title>
                <v-card-subtitle>
                  {{ userApplicationInfo.full_name }}({{
                    userApplicationInfo.name_cn
                  }})
                </v-card-subtitle>
                <v-card-text>
                  <v-alert type="warning">
                    退回申请会清除分组以及当前所有打分，确认操作吗？
                  </v-alert>
                  <v-textarea
                    v-model="returnReason"
                    :counter="1000"
                    auto-grow
                    outlined
                    label="原因 / Reason"
                  ></v-textarea>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    dark
                    @click="returnConfirm"
                    :disabled="!returnReason"
                  >
                    OK
                  </v-btn>
                  <v-btn color="grey" dark @click="returnClose"> Cancel </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Research Area & Interest</v-toolbar-title>
          </v-toolbar>
          <v-list two-line>
            <v-list-item-group>
              <template v-for="(item, index) in researchInfo">
                <v-list-item :key="item.area">
                  <v-list-item-title v-text="item.area"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.interest"
                  ></v-list-item-subtitle>
                </v-list-item>
                <v-divider
                  v-if="index < researchInfo.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-dialog
          persistent
          v-model="uploadReferenceLetterDialog"
          max-width="500px"
        >
          <v-card>
            <v-card-title class="headline">
              Upload Reference Letter
            </v-card-title>
            <v-card-subtitle>
              {{ uploadReferenceLetterFields.name }}({{
                uploadReferenceLetterFields.email
              }}) from {{ uploadReferenceLetterFields.institute }}
            </v-card-subtitle>

            <v-card-text>
              <v-file-input
                ref="referenceLetterUpload"
                @change="selectFile"
                chips
                show-size
                counter
                :accept="fileTypes.doc"
              ></v-file-input>
            </v-card-text>
            <v-card-actions v-if="waiting" class="justify-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-card-actions>
            <v-card-actions v-else class="justify-center">
              <v-btn color="grey" @click="uploadReferenceLetterClose">
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                :disabled="!uploadFile"
                @click="uploadReferenceLetterSubmit"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>References</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              :headers="referenceHeaders"
              :items="userApplicationInfo.references"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:[`item.sent`]="{ item }">
                <v-icon v-if="item.sent"> mdi-check </v-icon>
                <v-icon v-else> mdi-close </v-icon>
              </template>
              <template v-slot:[`item.received`]="{ item }">
                <template v-if="item.received">
                  <v-btn
                    color="primary"
                    icon
                    large
                    @click="getReferenceLetter(item, false)"
                  >
                    <v-icon>mdi-eye-circle</v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    icon
                    @click="getReferenceLetter(item, true)"
                  >
                    <v-icon>mdi-download-circle</v-icon>
                  </v-btn>
                </template>
                <template v-else>
                  <v-btn
                    color="warning"
                    icon
                    @click="resendReferenceLetterRequest(item)"
                    v-if="isAdmin"
                  >
                    <v-icon>mdi-email-fast-outline</v-icon>
                  </v-btn>
                  <v-btn
                    color="error"
                    icon
                    @click="handleUploadReferenceLetter(item)"
                    v-if="isAdmin"
                  >
                    <v-icon>mdi-upload</v-icon>
                  </v-btn>
                </template>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>

      <template v-for="round of rounds">
        <v-col cols="12" :key="round">
          <v-card v-if="round == 1 || reviewRound2">
            <v-toolbar color="primary" dark>
              <v-toolbar-title>Review Round {{ round }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-chip
                class="ma-2"
                label
                outlined
                color="white"
                v-if="round == 1"
              >
                deadline: {{ reviewDeadline }}
              </v-chip>
              <v-btn
                color="primary lighten-2"
                @click="addReviewForRound(round)"
                v-if="isProfessor"
              >
                Add Review
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-data-table
                :headers="reviewHeaders"
                :items="reviewsForRound(round)"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:[`item.professor`]="{ item }">
                  {{ item.professor.name }}
                </template>
                <template v-slot:[`item.score`]="{ item }">
                  <v-chip label :color="reviewScoreColor(item.score)">
                    {{ reviewScoreDescription(item.score) }}
                  </v-chip>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip
                    top
                    v-if="
                      item.professor.id == $store.state.currentUser.id &&
                      round == 1
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        @click="editReview(item)"
                      >
                        <v-icon> mdi-pencil </v-icon>
                      </v-btn>
                    </template>
                    <span>Last Updated: {{ item.update_time }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import requests from "@/http";
import consts from "@/consts";
import utils from "@/utils";

export default {
  data: () => ({
    rules: consts.INPUT_RULES,
    infoFields: consts.APPLICATION_FIELDS,
    basicFields: consts.BASIC_FIELDS,
    researchFields: consts.RESEARCH_FIELDS,
    positionTypes: consts.POSITION_TYPES,
    fileTypes: consts.FILE_TYPES,
    waiting: false,
    alerts: null,
    messages: null,
    applicationID: 0,
    userApplicationInfo: null,
    applicationStatus: "",
    returnReason: "",
    researchInfo: [],
    fieldsEditing: {
      full_name: false,
      name_cn: false,
      native_name: false,
      position_type: false,
      nationality: false,
      birthday: false,
      timezone: false,
      current_institute: false,
      current_position: false,
      current_address: false,
      phd_institute: false,
      phd_date: false,
      ads_link: false,
    },
    editFields: {
      full_name: null,
      name_cn: null,
      native_name: null,
      position_type: null,
      nationality: null,
      birthday: null,
      timezone: null,
      current_institute: null,
      current_position: null,
      current_address: null,
      phd_institute: null,
      phd_date: null,
      ads_link: null,
    },
    isAdmin: false,
    isProfessor: false,
    returnDialog: false,
    deleteMaterialDialog: false,
    uploadMaterialDialog: false,
    uploadReferenceLetterDialog: false,
    uploadReferenceLetterFields: {
      id: 0,
      name: "",
      email: "",
      institute: "",
    },
    uploadFile: null,
    referenceHeaders: [
      { text: "Name", value: "name" },
      { text: "Email", value: "email" },
      { text: "Institute", value: "institute" },
      { text: "Requested", value: "sent" },
      { text: "Actions", value: "received" },
    ],

    reviewHeaders: [
      { text: "Reviewer", value: "professor" },
      { text: "Score", value: "score" },
      { text: "Comment", value: "comment", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    reviews: [],
    rounds: [1, 2],
    scores: [3, 1, 0],
    editReviewDialog: false,
    editReviewFields: {
      round: 0,
      score: 0,
      comment: "",
    },

    reviewDeadline: "",
    reviewRound2: false,
  }),
  created() {
    this.applicationID = this.$route.params.id;
    this.getApplicationInfo();
    this.isAdmin =
      this.$store.state.currentUser.role == "owner" ||
      this.$store.state.currentUser.role == "admin";
    this.isProfessor = this.$store.state.currentUser.role == "professor";
  },
  methods: {
    reviewScoreColor: utils.reviewScoreColor,
    reviewScoreDescription: utils.reviewScoreDescription,

    getApplicationInfo() {
      requests
        .get(`/api/review/applications/${this.applicationID}`)
        .then((res) => {
          const info = res.data.info;
          this.userApplicationInfo = info;
          this.reviews = res.data.reviews;
          this.reviewDeadline = res.data.review_status.deadline;
          this.reviewRound2 = res.data.review_status.round2;
          this.applicationStatus = res.data.status;
          this.researchInfo = JSON.parse(info.research);
        })
        .catch((err) => {
          this.alerts = err.response.data.detail;
        });
    },
    handleEditField(field) {
      this.fieldsEditing[field] = true;
      this.editFields[field] = this.userApplicationInfo[field];
    },
    handleCancelEditField(field) {
      this.fieldsEditing[field] = false;
      this.editFields[field] = null;
    },
    handleConfirmEditField(field) {
      requests
        .post(`/api/admin/applications/${this.applicationID}/edit`, {
          field: field,
          content: this.editFields[field],
        })
        .then(() => {
          this.userApplicationInfo[field] = this.editFields[field];
          this.fieldsEditing[field] = false;
          this.editFields[field] = null;
        })
        .catch((err) => {
          this.alerts = err.response.data.detail;
        });
    },

    getReferenceLetter(item, download = false) {
      requests
        .get(
          `/api/review/applications/${this.applicationID}/letter/${item.id}`,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          this.waiting = false;
          if (download) {
            const title = `reference-letter-${this.userApplicationInfo.full_name}-${item.name}.pdf`;
            utils.forceFileDownload(res, title);
          } else {
            utils.forceFileDownload(res, null);
          }
        })
        .catch((err) => {
          this.waiting = false;
          console.log(err);
          alert("download reference letter failed, pls retry");
        });
    },
    resendReferenceLetterRequest(item) {
      this.waiting = true;
      requests
        .post(
          `/api/admin/applications/${this.applicationID}/reference/${item.id}/mail`
        )
        .then(() => {
          alert(
            "Email request sent, please wait for reference letter received!"
          );
          this.getApplicationInfo();
          this.waiting = false;
        })
        .catch((err) => {
          alert(err.response.data.detail);
          this.waiting = false;
        });
    },
    handleUploadReferenceLetter(item) {
      this.uploadReferenceLetterDialog = true;
      this.uploadReferenceLetterFields = item;
    },
    uploadReferenceLetterClose() {
      this.uploadReferenceLetterDialog = false;
      this.uploadReferenceLetterFields = {
        id: 0,
        name: "",
        email: "",
        institute: "",
      };
      this.$nextTick(() => {
        this.uploadFile = null;
        this.$refs.referenceLetterUpload.reset();
      });
    },
    uploadReferenceLetterSubmit() {
      this.waiting = true;
      const formData = new FormData();
      formData.append("file", this.uploadFile);
      requests
        .post(
          `/api/admin/applications/${this.applicationID}/reference/${this.uploadReferenceLetterFields.id}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.waiting = false;
          this.uploadReferenceLetterClose();
          this.getApplicationInfo();
        })
        .catch((err) => {
          this.waiting = false;
          alert(err.response.data.detail);
        });
    },

    getMaterial(download = false) {
      requests
        .get(`/api/review/applications/${this.applicationID}/material`, {
          responseType: "blob",
        })
        .then((res) => {
          this.waiting = false;
          if (download) {
            const title = `material-${this.userApplicationInfo.full_name}.pdf`;
            utils.forceFileDownload(res, title);
          } else {
            utils.forceFileDownload(res, null);
          }
        })
        .catch((err) => {
          this.waiting = false;
          console.log(err);
          alert("download material failed, pls retry");
        });
    },
    handleUploadMaterial() {
      this.uploadMaterialDialog = true;
    },
    uploadMaterialClose() {
      this.uploadMaterialDialog = false;
      this.$nextTick(() => {
        this.uploadFile = null;
        this.$refs.materialUpload.reset();
      });
    },
    uploadMaterialSubmit() {
      this.waiting = true;
      const formData = new FormData();
      formData.append("file", this.uploadFile);
      requests
        .post(
          `/api/admin/applications/${this.applicationID}/material/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          this.waiting = false;
          this.userApplicationInfo.material = res.data.file.id;
          this.uploadMaterialClose();
        })
        .catch((err) => {
          this.waiting = false;
          alert(err.response.data.detail);
        });
    },

    selectFile(file) {
      this.uploadFile = file;
    },

    handleDeleteMaterial() {
      this.deleteMaterialDialog = true;
    },
    deleteMaterialClose() {
      this.deleteMaterialDialog = false;
    },
    deleteMaterialConfirm() {
      requests
        .post(`/api/admin/applications/${this.applicationID}/material/delete`)
        .then(() => {
          this.userApplicationInfo["material"] = null;
          this.deleteMaterialClose();
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
    },

    reviewsForRound(round) {
      return this.reviews.filter((item) => item.round == round);
    },

    addReviewForRound(round) {
      this.editReviewFields.round = round;
      this.editReviewFields.score = 0;
      this.editReviewFields.comment = "";
      this.editReviewDialog = true;
    },
    editReview(item) {
      this.editReviewFields.round = item.round;
      this.editReviewFields.score = item.score;
      this.editReviewFields.comment = item.comment;
      this.editReviewDialog = true;
    },
    closeEditReview() {
      this.editReviewDialog = false;
      this.$nextTick(() => {
        this.editReviewFields = {
          round: 0,
          score: 0,
          comment: "",
        };
      });
    },
    handleEditReview() {
      requests
        .post(
          `/api/review/applications/${this.applicationID}/review`,
          this.editReviewFields
        )
        .then(() => {
          this.getApplicationInfo();
          this.closeEditReview();
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
    },

    toLocalTime(t) {
      return new Date(t).toLocaleString();
    },

    handlePrint() {
      this.waiting = true;
      requests
        .get(`/api/review/applications/${this.applicationID}/preview`, {
          responseType: "blob",
        })
        .then((res) => {
          this.waiting = false;
          const title = `application-${this.userApplicationInfo.full_name}.pdf`;
          utils.forceFileDownload(res, title);
        })
        .catch((err) => {
          this.waiting = false;
          console.log(err);
          alert("preview failed, pls retry");
        });
    },

    handleReturn() {
      this.returnDialog = true;
    },
    returnClose() {
      this.returnDialog = false;
    },
    returnConfirm() {
      requests
        .post(`/api/admin/applications/${this.applicationID}/return`, {
          reason: this.returnReason,
        })
        .then(() => {
          this.getApplicationInfo();
          this.returnClose();
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
    },
  },
};
</script>
